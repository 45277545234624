import { gql } from 'graphql-request';

const verdDriversQuery = gql`
  query($storeId: Int!, $branchId: [Int], $driverId: Int) {
    allStoreDrivers(storeId: $storeId, branchIds: $branchId, driverId: $driverId) {
      id
      name
      phone
      email
      branches
      onShift
      jobType
      language
      assignedOrdersCount
    }
  }
`;

export default verdDriversQuery;
