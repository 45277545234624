import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { context as userContext } from 'context/user';
import { RESTAURANT_COURIER_AUTHENTICATION_TYPE } from 'constants/restaurant';
import * as CONSTANTS from 'constants/integrations';
import { Text } from 'components/service';
import { Section } from 'components/form/generic';
import * as translations from 'constants/translations';
import PosIntegration from './POS';
import CourierIntegration from './Courier';

const IntegrationSection = ({ setIntegrationValidity, branchId, setFieldValue, posBranchId, couriers }) => {
  const { courier, isDeliveryCourier, isPosCourier, pos } = useContext(userContext);
  const isAuthPerBranch = courier?.authenticationType === RESTAURANT_COURIER_AUTHENTICATION_TYPE.BRANCH_KEY;
  const showSection = isPosCourier || isAuthPerBranch;

  useEffect(() => {
    if (showSection) {
      branchId ? setIntegrationValidity(true) : setIntegrationValidity(true);
      if (isPosCourier) setFieldValue('isPOS', true);
      if (isDeliveryCourier) setFieldValue('isCourier', true);
      if (pos?.courierDetails.name === CONSTANTS.OCIMS) {
        setFieldValue('isOcims', true);
      }
    }
  }, [branchId, isDeliveryCourier, isPosCourier, setFieldValue, setIntegrationValidity, showSection, pos]);

  return (
    <Section title={<Text value={translations.INTEGRATIONS} />}>
      {isPosCourier && (
        <PosIntegration
          {...{
            branchId,
            setFieldValue,
            posBranchId,
          }}
        />
      )}

      {isAuthPerBranch && isDeliveryCourier && (
        <CourierIntegration
          {...{
            branchId,
            setFieldValue,
            couriers,
          }}
        />
      )}
    </Section>
  );
};

IntegrationSection.propTypes = {
  setIntegrationValidity: PropTypes.func.isRequired,
  branchId: PropTypes.string.isRequired,
  posBranchId: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
};

export default IntegrationSection;
