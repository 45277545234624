import React, { useContext, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@zydalabs/zac-react';
import { CheckIcon } from '@zydalabs/zac-icons-react';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import TextDetails from './TextDetails';

const StoreCourier = ({ courier, quickAssignCourierId, selected, setSelected }) => {
  const { lang, translate } = useContext(localeContext);
  const {
    selectedStore: { currency },
  } = useContext(userContext);
  const { colors } = useTheme();

  const courierNameEn = courier.courierDetails.displayNameEn;
  const courierNameAr = courier.courierDetails.displayNameAr;
  const courierId = Number(courier?.courierId);
  const isArabic = lang === 'ar';
  const isCourierSelected = selected?.courierId === courier?.courierId;

  useEffect(() => {
    if (courierId === quickAssignCourierId) setSelected(courier);
  }, [courier]);

  const { pickupTime, success, supported, deliveryTime, deliveryFees, verdNetworkFees } =
    courier.courierEstimations || {};

  const pickupOrDeliveryTimeText = pickupTime
    ? translate(translations.PICKUP_ESTIMATIONS(pickupTime))
    : translate(translations.DELIVERY_ESTIMATIONS(deliveryTime));

  const deliveryFeesText = `${translate(translations.ESTIMATED_COST)} ${translate(currency)} ${deliveryFees?.toFixed(
    currency.decimals,
  )} `;

  const verdNetworkFeesText =
    (!!verdNetworkFees || verdNetworkFees !== 0) &&
    `${translate(translations.SERVICE_FEES)} ${translate(currency)} ${verdNetworkFees?.toFixed(currency.decimals)}`;

  return (
    <div
      className={cx(
        'w-100 flex border items-center justify-between mb-2 rounded-lg py-3 px-4 cursor-pointer',
        isCourierSelected && 'border-blue-500 bg-blue-100',
      )}
      onClick={() => setSelected(courier)}
      aria-hidden="true"
    >
      <div className="flex gap-3 items-center">
        <div className="w-6 h-6">
          <img className="rounded-full border border-gray-100 shadow-sm" src={courier.logo} alt="kk" />
        </div>
        <div>
          <Typography variant="heading14" mb={4} cursor="pointer">
            {isArabic ? courierNameAr : courierNameEn}
          </Typography>
          {supported && success ? (
            <>
              <TextDetails>
                {pickupOrDeliveryTimeText} • {deliveryFeesText} + {verdNetworkFeesText}
              </TextDetails>
            </>
          ) : (
            <TextDetails>{translate(translations.NO_COURIER_ESTIMATION)}</TextDetails>
          )}
        </div>
      </div>
      {isCourierSelected && <CheckIcon width="25px" color={colors.blue.primary} />}
    </div>
  );
};
StoreCourier.propTypes = {
  order: PropTypes.shape({
    number: PropTypes.string,
    id: PropTypes.number,
    status: PropTypes.string,
    deliveryCourier: {
      driverAssigned: PropTypes.bool,
      driverName: PropTypes.string,
      driverPhoneNumber: PropTypes.number,
      referenceId: PropTypes.string,
      externalOrderIdentifierLink: PropTypes.string,
      externalOrderIdentifierType: PropTypes.string,
      trackingLink: PropTypes.string,
      hasDriverInfo: PropTypes.bool,
      courierDetails: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        displayNameAr: PropTypes.string,
        displayNameEn: PropTypes.string,
      }),
    },
  }),
  courier: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    displayNameEn: PropTypes.string,
    displayNameAr: PropTypes.string,
    supportCancellation: PropTypes.bool,
    courierId: PropTypes.string,
    courierDetails: PropTypes.shape({
      displayNameAr: PropTypes.string,
      displayNameEn: PropTypes.string,
    }),
    courierEstimations: PropTypes.shape({
      courierId: PropTypes.number,
      deliveryFees: PropTypes.number,
      deliveryTime: PropTypes.number,
      pickupTime: PropTypes.number,
      success: PropTypes.bool,
      supported: PropTypes.bool,
    }),
  }),
  quickAssignCourierId: PropTypes.number,
};

export default StoreCourier;
