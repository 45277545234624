import { useContext } from 'react';
import { useQuery } from '@apollo/client';

import { THIRD_PARTY_TYPE_ENUM } from 'utils/enums';
import { useDeliveryZoneMsToken } from 'hooks';
import { useFetchCouriersEstimations } from 'service/hooks';
import { camelCaseKeys } from 'pages/setup/paymentMethods/PaymentGateway/utils';
import { context as userContext } from 'context/user';
import { DELIVERY_ENUMS } from '../../../constants';
import * as schemas from '../../../schemas';

const useGetCourierInfoWithLogo = order => {
  const {
    selectedStore: { restaurantCourierSetting },
  } = useContext(userContext);
  const deliveryZoneMsToken = useDeliveryZoneMsToken();
  const filteredRestaurantCourierSetting = restaurantCourierSetting.filter(
    courier => !courier.isInternalDelivery && courier.businessType === THIRD_PARTY_TYPE_ENUM.Delivery,
  );

  const { data: storeCouriersList } = useQuery(schemas.LIST_COURIERS, {
    ...(deliveryZoneMsToken && {
      headers: {
        token: deliveryZoneMsToken,
      },
    }),
    variables: {
      businessType: DELIVERY_ENUMS.DELIVERY,
    },
    skip: filteredRestaurantCourierSetting.length === 0,
  });
  const restaurantCouriersIds = storeCouriersList?.couriers.map(courier => courier.id);

  const {
    data: couriersEstimations,
    isLoading: isCouriersEstimationsLoading,
    error: courierEstimationsError,
  } = useFetchCouriersEstimations({
    number: order.number,
  });

  const couriersWithEstimations =
    couriersEstimations && JSON.parse(couriersEstimations)?.map(courier => camelCaseKeys(courier));
  const couriersWithEstimationsIds = couriersWithEstimations?.map(courier => courier.courierId);

  const restaurantCouriersWithLogos = filteredRestaurantCourierSetting?.map(courier => {
    const normalizingCourierId = Number(courier.courierId);
    let courierLogo;
    let courierEstimations;
    if (restaurantCouriersIds?.includes(normalizingCourierId)) {
      const matchedCourier = storeCouriersList?.couriers.find(
        targetCourier => targetCourier.id === normalizingCourierId,
      );
      courierLogo = matchedCourier?.logoUrl;
    }
    if (couriersWithEstimationsIds?.includes(normalizingCourierId)) {
      const matchedCourier = couriersWithEstimations?.find(
        targetCourier => targetCourier.courierId === normalizingCourierId,
      );
      courierEstimations = matchedCourier;
    }
    return { ...courier, courierEstimations, logo: courierLogo };
  });
  return { restaurantCouriersWithLogos, isCouriersEstimationsLoading, courierEstimationsError };
};

export default useGetCourierInfoWithLogo;
